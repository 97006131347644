var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('div',{staticClass:"conOfHeader"},[_c('div',{staticClass:"headerOfPage"},[_vm._v("Project Tasks")])])])],1),(_vm.listData && _vm.listData.length > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('section',{staticClass:"conOfProjects"},[_c('div',{staticClass:"conOfTable"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.listData,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
                  name: 'projectAttendancebyID',
                  params: { project_id: item.project_id, task_id: item.id },
                }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.tags",fn:function(ref){
                var item = ref.item;
return _vm._l((item.tags),function(tag){return _c('div',{key:tag.name},[_vm._v(" "+_vm._s(tag.name)+" ")])})}}],null,true)})],1)]),_c('div',{staticClass:"conOfPagination pr-5 pt-5"},[_c('v-pagination',{attrs:{"total-visible":7,"length":_vm.pagination.last_page},on:{"input":function($event){return _vm.goToPage(_vm.pagination.current_page)}},model:{value:(_vm.pagination.current_page),callback:function ($$v) {_vm.$set(_vm.pagination, "current_page", $$v)},expression:"pagination.current_page"}})],1)])],1):(_vm.isLoading)?_c('div',{staticClass:"loaderContainer"},[_c('v-progress-circular',{attrs:{"size":120,"width":4,"indeterminate":"","color":"primary"}})],1):_c('v-row',[_c('v-col',{staticClass:"mt-7",attrs:{"cols":"12"}},[_c('EmptyState')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }